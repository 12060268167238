import { useEffect, useState, useContext } from "react";
import { Grid, Ellipsis, Tag, Image, Toast, Space, Button, Dialog } from "antd-mobile";
import copy from 'copy-to-clipboard';

import DistanceButton from '@/components/distancebutton.js';
import imgRecommend from '@/asset/remmcond.png';

import { Ajax } from "@/api/http.js";
import { GlobalContext } from "@/App.js";

export default function GirlBox(props){
    const {globalData} = useContext(GlobalContext);

    const [isHidden,setIsHidden] = useState(false);
    const [contacts,setContacts] = useState([]);

    useEffect(()=>{
        setIsHidden(props.data.isHidden);

        //生成联系方式列表
        let tmpCs=[];
        for(let i of globalData.contacts){
            if(i in props.data.phone){
                tmpCs.push({
                    'name':i,
                    'value':props.data.phone[i]
                })
            }
        }
        setContacts(tmpCs);

    },[props.data.id])

    //点击复制地址
    function onCopy(val,msg){
        copy(val);
        Toast.show({
            content:msg+'已复制'
        })
    }

    //屏蔽或取消屏蔽
    async function onHidden(){
        if(!globalData.userConfigIsOpen){
            Dialog.alert({
                content:'必须开通了客服功能后才能隐藏妹子！'
            })
            return;
        }
        const ru =await Dialog.confirm({
            title:'请确认',
            content: isHidden ? '您确定要显示此妹子吗？' : '您确定要隐藏此妹子吗？'
        });
        if(ru){
            let url='/user/sethidden';
            Ajax(url,'post',{'id':props.data.id,'isHidden':!isHidden}).then(res=>{
                Toast.show({
                    icon:'success',
                    content:'设置成功！',
                    afterClose:()=>{
                        setIsHidden(!isHidden);
                    }
                })
            })
        }
    }

    return (
        <div style={{overflow:'hidden'}}>
            <div style={{position:'relative'}}>
                <Image
                    src={props.data.logo}
                    style={{borderRadius:'8px 8px 0 0',width:'100%',maxHeight:'220px',minHeight:'220px'}}
                    lazy={true}
                    onClick={()=>props.onShowGirlDetail(props.data)}
                    onContainerClick={()=>props.onShowGirlDetail(props.data)}
                />
                {props.data.price && (
                    <Tag color='danger' style={{position:'absolute',left:0,bottom:0}}>
                        费用: {props.data.price}
                    </Tag>
                )}
                {props.data.isRecommend && 
                    <div style={{position:'absolute',top:'0',left:'0'}}>
                        <img src={imgRecommend} width={46} />
                    </div>
                }
                {props.data.isReal && 
                    <div style={{position:'absolute',top:'0',right:'0',backgroundColor:'green',color:'#fff',fontSize:'12px',padding:'4px 4px',borderRadius:'0px 8px 0 0'}}>
                        平台已认证
                    </div>
                }
                {isHidden && 
                    <div style={{
                        position:'absolute',width:'110px',height:'30px',backgroundColor:'#000',color:'#fff',
                        textAlign:'center',lineHeight:'30px',left:'0',top:'0',bottom:'0',right:'0',margin:'auto'
                    }}>
                        已被您隐藏
                    </div>
                }
            </div>
            <div style={{padding:'10px 8px',lineHeight:'20px',backgroundColor:'#efefef'}}>
                <Ellipsis 
                    rows={1} 
                    direction='end' 
                    content={props.data.name} 
                    expandText='' 
                    collapseText='' 
                    style={{fontSize:'16px'}}
                />
                <div style={{borderTop:'1px solid #ccc',margin:'8px 0'}}></div>
                <Grid columns={2}>
                    <Grid.Item><span style={{color:'green'}}>年齡: </span>{props.data.age}</Grid.Item>
                    <Grid.Item><span style={{color:'green'}}>身高: </span>{props.data.height}</Grid.Item>
                </Grid>
                <Grid columns={2}>
                    <Grid.Item><span style={{color:'green'}}>體重: </span>{props.data.weight}</Grid.Item>
                    <Grid.Item><span style={{color:'green'}}>胸圍: </span>{props.data.bust}</Grid.Item>
                </Grid>
                {props.data.tags.length>0 && (
                    <div style={{margin:'8px 0 0 0'}}>
                        <Space wrap>
                            {props.data.tags.map((one,index)=>
                                <Tag color='danger' key={index}>{one}</Tag>
                            )}
                        </Space>
                    </div>
                )}
                <div style={{margin:'8px 0'}}>
                    <DistanceButton 
                        lng={props.data.lng} 
                        lat={props.data.lat} 
                    />
                </div>
                {props.data.basePrice && (
                    <Grid columns={1}>
                        <Grid.Item>
                            <span style={{color:'green'}}>原价:</span>
                            <a
                                style={{margin:'0 0 0 2px',color:'#000'}}
                                onClick={()=>onCopy(props.data.basePrice,'原价')}
                            >
                                {props.data.basePrice}
                            </a>
                        </Grid.Item>
                    </Grid>
                )}
                {props.data.address && (
                    <Grid columns={1}>
                        <Grid.Item>
                            <span style={{color:'green'}}>地址:</span>
                            <a 
                                style={{margin:'0 0 0 2px',color:'#000'}} 
                                onClick={()=>onCopy(props.data.address,'地址')}
                            >
                                {props.data.address}
                            </a>
                        </Grid.Item>
                    </Grid>
                )}
                
                {contacts.map((one,index)=>
                    <Grid columns={1} key={index}>
                        <Grid.Item>
                            <span style={{color:'green'}}>{one['name']}: </span>
                            <a 
                                style={{margin:'0 0 0 2px',color:'#000'}} 
                                onClick={()=>onCopy(one['value'],one['name'])}
                            >
                                    {one['value']}
                            </a>
                        </Grid.Item>
                    </Grid>
                )}
                {props.data.note && (
                    <Grid columns={1}>
                        <Grid.Item>
                            <span style={{color:'green'}}>备注: </span>
                            <a 
                                style={{margin:'0 0 0 2px',color:'#000'}} 
                                onClick={()=>onCopy(props.data.note,'备注')}
                            >
                                    {props.data.note}
                            </a>
                        </Grid.Item>
                    </Grid>
                )}
                {globalData.userToken &&
                    <Button block color='warning' size='small' style={{margin:'10px 0px 6px 0'}} onClick={onHidden}>
                        {isHidden ? '取消隐藏' : '隐藏'}
                    </Button>
                }
            </div>
        </div>
    )
}