import { useEffect, useState } from "react";
import { Grid, List, Card, DatePicker, Button, NavBar } from 'antd-mobile'

import { Ajax } from '../api/http.js';

export default function Charges(){

    const [data,setData] = useState({
        'userAmount':'',
        'goldAmount':'',
        'moneyAmount':'',
        'listData':[]
    });

    const [dpVisible,setDpVisible] = useState(false);
    const [dpValue,setDpValue] = useState(new Date());

    const [dpEndVisible,setDpEndVisible] = useState(false);
    const [dpEndValue,setDpEndValue] = useState(new Date());

    useEffect(()=>{
        getData();
    },[])

    function getData(){
        let dt=dpValue.getFullYear()+'-'+parseInt(dpValue.getMonth()+1)+'-'+dpValue.getDate();
        let dt2=dpEndValue.getFullYear()+'-'+parseInt(dpEndValue.getMonth()+1)+'-'+dpEndValue.getDate();
        Ajax('/gold/getcharges','post',{
            'startDate':dt,
            'endDate':dt2
        }).then(res=>{
            setData({
                'userAmount':res['userAmount'],
                'goldAmount':res['goldAmount'],
                'moneyAmount':res['moneyAmount'],
                'listData':res['data']
            });
        })
    }

    return (
        <>
            <NavBar 
                children='充值记录' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{width:'96%',padding:'0 2% 70px 2%'}}>
                <div style={{textAlign:'center',fontSize:'18px',margin:'20px 0 0 0'}}>
                    <strong>日期：</strong>
                    <span onClick={()=>setDpVisible(true)}>{dpValue?.toLocaleDateString()}</span>
                    <span> - </span>
                    <span onClick={()=>setDpEndVisible(true)}>{dpEndValue?.toLocaleDateString()}</span>
                    <Button 
                        color='success'
                        size="small"
                        style={{margin:'0 0 0 10px'}}
                        onClick={getData}
                    >
                        确定
                    </Button>
                </div>
                <DatePicker 
                    title='选择开始日期' 
                    visible={dpVisible}
                    onClose={()=>setDpVisible(false)}
                    onConfirm={(v)=>{
                        setDpValue(v);
                    }}
                />
                <DatePicker 
                    title='选择截止日期' 
                    visible={dpEndVisible}
                    onClose={()=>setDpEndVisible(false)}
                    onConfirm={(v)=>{
                        setDpEndValue(v);
                    }}
                />
                <h3 style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>统计信息</h3>
                <Grid columns={3} gap={5}>
                    <Grid.Item>
                        <Card title='用户数' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data.userAmount}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='金币' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data.goldAmount}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='金额' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data.moneyAmount}</div>
                        </Card>
                    </Grid.Item>
                </Grid>
               
                <List style={{margin:'20px 0'}}>
                    <List.Item 
                        extra={
                            <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>充值时间</div>
                        }
                    >
                        <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>充值记录</div>
                    </List.Item>
                    {data.listData.map((one,index)=>(
                        <List.Item 
                            key={index}
                            description={<>金币:{one.amount}，金额:{one.money}</>}
                            extra={<>{one.createdTime}</>}
                        >
                            {one.userName}
                        </List.Item>
                    ))}
                </List>
            </div>
        </>
    )
}

