import { useEffect, useState, useContext } from "react";
import { Button, Form, Input, Toast, Dialog, TextArea, NavBar, Selector} from "antd-mobile";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function UserConfig(){
    const {globalData} = useContext(GlobalContext);

    const [form]=Form.useForm();

    const [isOpenConfig,setIsOpenConfig] = useState(false);
    const [configEndTime,setConfigEndTime] = useState('尚未开通');

    useEffect(()=>{
        //获取已有设置
        Ajax('/user/getconfig','post').then(res=>{
            setIsOpenConfig(res['data']['isOpenConfig']);
            setConfigEndTime(res['data']['configEndTime']);

            form.setFieldsValue({
                'viewPassword':res['data']['viewPassword'],
                'viewMoney':res['data']['viewMoney'],
                'viewAddress':[res['data']['viewAddress']],
                'viewNumber':[res['data']['viewNumber']],
                'siteName':res['data']['siteName'],
                'siteMessage':res['data']['siteMessage']
            });
        });
    },[])

    //点击开通续费按钮
    async function toOpenConfig(){
        const result = await Dialog.confirm({
            content: '您确定要开通或续费此功能吗？'
        })
        if(result){
            Ajax('/user/chargeconfig','post').then(res=>{
                Toast.show({
                    icon:'success',
                    content:'操作成功,本次使用'+res['data']['useGold']+'金币，剩余'+res['data']['restGold']+'金币',
                    afterClose:()=>{
                        setIsOpenConfig(true);
                        setConfigEndTime(res['data']['configEndTime']);
                    }
                });
            })
        }
    }

    //提交表单
    const onFinsih=(vals)=>{
        if(vals['viewAddress'].length==0){
            vals['viewAddress']=false;
        }else{
            vals['viewAddress']=vals['viewAddress'][0]
        }
        if(vals['viewNumber'].length==0){
            vals['viewNumber']=false;
        }else{
            vals['viewNumber']=vals['viewNumber'][0];
        }
        console.log(vals);
        Ajax('/user/setconfig','post',vals).then(res=>{
            Toast.show({
                icon:'success',
                content:'设置成功！'
            })
        })
    }
    
    return (
        <>
            <NavBar 
                children='客服设置' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{margin:'15px'}}>
                <p style={{fontSize:'15px'}}>
                此功能供客服使用，开通后您可以设置自己专属的邀请码发给大哥，大哥输入这个邀请码打开网站后， 网站会根据您的设置进行显示。</p>
                <p>请注意，退出登陆后以您的邀请码进入后才能看到效果！</p>
                <p style={{fontSize:'15px', color:'red',fontWeight:'bold'}}>每次续费延长：{globalData.userConfigDays}天，消耗：{globalData.userConfigGold}金币</p>
                <p style={{fontSize:'15px', color:'red',fontWeight:'bold'}}>当前有效期：{configEndTime}</p>
                <p style={{fontSize:'15px'}}>有效期截止后需要续费才能继续使用！</p>
                <Button color='primary' size='middle' block onClick={toOpenConfig}>
                    点击开通或续费
                </Button>
            </div>
            <Form
                form={form}
                style={{margin:'30px 0'}}
                footer={
                    <Button block type='submit' color='primary' size='large' disabled={!isOpenConfig}>保存设置</Button>
                }
                onFinish={onFinsih}
                disabled={!isOpenConfig}
            >
                <Form.Item name='viewPassword' label='邀请码(6位数,不能都是数字)'>
                    <Input placeholder="6位以上字母和数字"></Input>
                </Form.Item>
                <Form.Item name='viewMoney' label='自动加价金额(若填写必须大于600)'>
                    <Input placeholder="比如800，如果不填则不显示价格"></Input>
                </Form.Item>
                <Form.Item name='viewAddress' label='妹子地址'>
                    <Selector
                        options={[
                            {
                                label:'显示地址',
                                value:true
                            },
                            {
                                label:'不显示地址',
                                value:false
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item name='viewNumber' label='妹子名称'>
                    <Selector 
                        options={[
                            {
                                label:'显示名称',
                                value:false
                            },
                            {
                                label:'只显示编号',
                                value:true
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item name='siteName' label='网站名称'>
                    <Input placeholder="网站的名称和标题"></Input>
                </Form.Item>
                <Form.Item name='siteMessage' label='访客通知'>
                    <TextArea placeholder="给访客们的通知内容，将会在首页弹出" autoSize={true} rows={4}></TextArea>
                </Form.Item>
            </Form>
        </>
    )
}
