import { useEffect, useContext } from "react";
import { NavBar, Image } from "antd-mobile";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function GirlDetail(props){
    const {globalData} = useContext(GlobalContext);

    useEffect(()=>{
        //阻止浏览器后退
        window.history.pushState(null, null, document.URL);
        window.onpopstate = function (e) {
            window.history.pushState(null, null, document.URL);
            props.onClose();
        };
        return () => {
            window.onpopstate = null;
        };
    },[])

    useEffect(()=>{
        if(props.data.id){
            Ajax('/visit/add','post',{
                'url':window.location.href,
                'girlId':props.data.id,
                'viewPassword':globalData.viewPassword
            },false,false,false);
        }
    },[props.data])

    return (
        <>
            <NavBar 
                onBack={props.onClose}
                style={{position:'sticky',top:'0',zIndex:9999,height:'50px',backgroundColor:'#fff'}}
            >
                {props.data.name}
            </NavBar>
            <div style={{padding:'0 10px 100px 10px'}}>
                {props.data.imgs.map((one,index)=>
                    <div key={index} style={{position:'relative',margin:'5px 0'}}>
                        <Image src={one} weight='100%' lazy={true}></Image>
                    </div>
                )}
                {props.data.videos.map((one,index)=>
                    <video
                        key={index}
                        src={one.url} 
                        controls="controls" 
                        style={{display:'block',margin:'10px auto',width:'100%',maxWidth:'100%'}}
                        poster={one.thumbnailUrl}
                    />
                )}
                {props.data.realVideos.map((one,index)=>
                    <video
                        key={index}
                        src={one.url} 
                        controls="controls" 
                        style={{display:'block',margin:'10px auto',width:'100%',maxWidth:'100%'}}
                        poster={one.thumbnailUrl}
                    />
                )}
            </div>
        </>
    )
}