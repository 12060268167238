import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Image } from "antd-mobile";

import { Ajax } from "@/api/http";
import { GlobalContext } from "@/App.js";

export default function OneGirl(){
    const {globalData} = useContext(GlobalContext);

    let {id} = useParams();

    const [data,setData]= useState({
        'name':'',
        'age':'',
        'height':'',
        'weight':'',
        'bust':'',
        'imgs':[],
        'videos':[],
        'realVideos':[]
    });

    useEffect(()=>{
        Ajax('/girl/viewone','post',{'id':id}).then(res=>{
            setData(res['data']);
            document.title=res['data']['name'];

            //增加访问记录
            Ajax('/visit/add','post',{
                'url':window.location.href,
                'girlId':id,
                'viewPassword':globalData.viewPassword
            },false,false,false);
        })
    },[])

    return (
        <>
            <h1 style={{textAlign:'center'}}>{data.name}</h1>
            <div style={{margin:'0 10px 20px 10px'}}>
                {data.imgs.map((one,index)=>
                    <div key={index} style={{position:'relative',margin:'5px 0'}}>
                        <Image src={one} lazy={true} style={{maxWidth:'100%'}}></Image>
                    </div>
                )}
                {data.videos.map((one,index)=>
                    <video
                        key={index}
                        src={one.url} 
                        controls="controls" 
                        style={{display:'block',margin:'10px auto',width:'100%',maxWidth:'100%'}}
                        poster={one.thumbnailUrl}
                    />
                )}
                {data.realVideos.map((one,index)=>
                    <video
                        key={index}
                        src={one.url} 
                        controls="controls" 
                        style={{display:'block',margin:'10px auto',width:'100%',maxWidth:'100%'}}
                        poster={one.thumbnailUrl}
                    />
                )}
            </div>
        </>
    )
}