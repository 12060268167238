import { useEffect, useState, useRef, useContext } from "react";
import { useSize } from 'ahooks';
import { SearchBar, Tabs, Grid, Popup, Divider, Dialog, TextArea, InfiniteScroll, NoticeBar } from "antd-mobile";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

import GirlBox from "@/components/girlbox";
import GirlDetail from "@/components/girldetail.js";

export default function Index(){
    const {globalData} = useContext(GlobalContext);
 
    //搜索状态
    const [word,setWord] = useState('');

    //标签页
    const [tabKey,setTabKey] = useState(globalData.areas[0]);

    //妹子详情
    const [detailVisible,setDetailVisible] = useState(false);
    const [detailData,setDetailData] = useState({});

    useEffect(()=>{
        //3秒后增加访问记录
        setTimeout(AddIndexVisit,3000);

        //3秒后弹出公告
        setTimeout(showDia,3000);
    },[])

    //增加访问记录
    function AddIndexVisit(){
        Ajax('/visit/add','post',{
            'url':window.location.href,
            'viewPassword':globalData.viewPassword
        },false,false,false);
    }

    //弹窗公告,如果已登陆，弹出系统公告(有的话)，如果未登陆，弹出自定义公告(有的话)
    function showDia(){
        let content=null;
        if(globalData.userToken && globalData.userMsg){
            content=globalData.userMsg;
        }else{
            if(globalData.siteMessage){
                content=globalData.siteMessage;
            }
        }
        if(content){
            Dialog.alert({
                title:<>通知</>,
                content:(
                    <TextArea
                        defaultValue={content}
                        autoSize={true} 
                        readOnly={true}
                        style={{'--font-size':'14px',padding:'0 10px 0 0'}}
                        rows={10}
                    />
                )
            })
        }
    }

    //打开妹子详情窗口
    function onOpenGirlDetail(girlData){
        setDetailVisible(true);
        setDetailData(girlData);
    }

    //关闭妹子详情窗口
    function onCloseGirlDetail(){
        setDetailVisible(false);
    }

    return (
        <>
            {globalData.userToken && 
                <NoticeBar color='alert' wrap content={globalData.userMsg}></NoticeBar>
            }
            <SearchBar
                style={{margin:'10px 2%'}}
                placeholder='输入名称/标签/地址/编号搜索'
                onSearch={(v)=>setWord(v)}
                onClear={()=>setWord(null)}
            />
            <div style={{position:'sticky',zIndex:5,top:'-1px',backgroundColor:'#fff'}}>
                <Tabs activeKey={tabKey} onChange={(key)=>setTabKey(key)}>
                    {globalData.areas.map((area)=>(
                        <Tabs.Tab title={area} key={area}></Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            <MySwiper
                areas={globalData.areas}
                showArea={tabKey}
                word={word}
                onShowGirlDetail={onOpenGirlDetail}
            />
            <Divider></Divider>
            <div style={{textAlign:'center',color:'#777',padding:'0 10% 70px 10%'}}>
                <p>任何人都可以使用此相冊，設置隱私密碼後分享給您喜歡的朋友</p>
                <p>訂製服務請聯繫:+6564151888</p>
                <p>公司地址：25 Serangoon North Avenue 5 #02-01 Keppel DC, 25 Serangoon North Ave 5, SGP1, 554914</p>
                <p>Copyright @2020 Keppel Data Centres Pte Ltd. All rights reserved.</p>
            </div>
            <Popup
                visible={detailVisible}
                destroyOnClose={true}
                onMaskClick={onCloseGirlDetail}
                position="right"
                bodyStyle={{height:'100vh',width:'100vw',overflowY:'auto'}}
            >
                <GirlDetail data={detailData} onClose={onCloseGirlDetail}></GirlDetail>
            </Popup>
        </>
    )
}

function MySwiper(props){
    const [showArea,setShowArea] = useState(null);
    
    const ref = useRef(null);
    const size = useSize(ref);
    const [colNum,setColNum]=useState(2);

    useEffect(()=>{
        if(size){
            let n = size.width/220;
            n=parseInt(n);
            if(n<2){
                n=2;
            }
            setColNum(n);
        }
    },[size])

    useEffect(()=>{
        setShowArea(props.showArea);
    },[props.showArea])

    return (
        <div ref={ref} style={{margin:'0 1%'}}>
            {props.areas.map((area,index)=>(
                <Girls colNum={colNum} showArea={showArea} area={area} word={props.word} onShowGirlDetail={props.onShowGirlDetail} key={index} />
            ))}
        </div>
    )
}

function Girls(props){
    const {globalData} = useContext(GlobalContext);

    const [listData,setListData] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [pageCurrent,setPageCurrent] = useState(1);
    const pageSize=10;

    const [randNum]=useState(Math.floor(Math.random()*(100000)));

    const [isShow,setIsShow] = useState(false);

    useEffect(()=>{
        if(props.area==props.showArea){
            setIsShow(true);
        }else{
            setIsShow(false);
        }
    },[props.area,props.showArea])

    useEffect(()=>{
        if(props.area){
            setPageCurrent(1);
            setListData([]);
            setHasMore(true);
        }
    },[props.area,props.word])

    //加载妹子列表
    async function loadGirls(){
        try {
            console.log(props.area);
            const res = await Ajax('/girl/viewlist', 'post', {
                'viewPassword': globalData.viewPassword,
                'area': props.area,
                'word': props.word,
                'pageCurrent': pageCurrent,
                'pageSize': pageSize,
                'randNum': randNum
            });
            setListData(val => [...val, ...res['data']]);
            setHasMore(res['data'].length > 0);
            setPageCurrent(pageCurrent + 1);
        } catch (err) {
            setHasMore(false);
        }
   }

    return (
        <div style={{margin:'10px 0',display: isShow ? 'block' :'none'}}>
            <Grid columns={props.colNum} gap={10}>
                {listData.map((one)=>(
                    <Grid.Item key={one.id}>
                        <GirlBox data={one} onShowGirlDetail={props.onShowGirlDetail} />
                    </Grid.Item>
                ))}
            </Grid>
            <InfiniteScroll loadMore={loadGirls} hasMore={hasMore} threshold={50} />
        </div>
    )
}