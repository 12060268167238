import { useEffect, useState, useContext } from "react";
import { Grid, List, Card, DatePicker, Button, NavBar } from 'antd-mobile'

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function Report(){
    const {globalData} = useContext(GlobalContext);

    const [data,setData] = useState({
        'girlState':{
            'all':0,
            'work':0,
            'rest':0,
            'real':0,
            'recommend':0
        },
        'allData':{
            'nowIp':0,
            'ip':0,
            'pv':0
        },
        'userData':[],
        'girlData':[]
    });

    const [dpVisible,setDpVisible] = useState(false);
    const [dpValue,setDpValue] = useState(new Date());

    useEffect(()=>{
        getData(dpValue);
    },[])

    function getData(d){
        let dt=d.getFullYear()+'-'+parseInt(d.getMonth()+1)+'-'+d.getDate();
        Ajax('/visit/report','post',{
            'startDate':dt,
            'endDate':dt
        }).then(res=>{
            setData(res['data']);
        })
    }

    return (
        <>
            <NavBar 
                children='统计报表' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{width:'96%',padding:'0 2%'}}>
                <div style={{textAlign:'center',fontSize:'18px',margin:'20px 0 0 0'}}>
                    <strong>日期：</strong>
                    <span>{dpValue?.toLocaleDateString()}</span>
                    <Button 
                        color='success'
                        size="small"
                        style={{margin:'0 0 0 10px'}}
                        onClick={()=>setDpVisible(true)}
                    >
                        更改
                    </Button>
                </div>
                <DatePicker 
                    title='选择日期' 
                    visible={dpVisible}
                    onClose={()=>setDpVisible(false)}
                    onConfirm={(v)=>{
                        setDpValue(v);
                        getData(v);
                    }}
                />

                <h3 style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>我的妹子</h3>
                <Grid columns={3} gap={5}>
                    <Grid.Item>
                        <Card title='全部' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['girlState']['all']}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='上班/休息' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['girlState']['work']}/{data['girlState']['rest']}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='认证/置顶' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['girlState']['real']}/{data['girlState']['recommend']}</div>
                        </Card>
                    </Grid.Item>
                </Grid>
                 
                <h3 style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>我的流量(依据邀请码统计)</h3>
                <Grid columns={3} gap={5}>
                    <Grid.Item>
                        <Card title='当前在线' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['allData']['nowIp']}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='独立访客' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['allData']['ip']}</div>
                        </Card>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='访问人次' style={{backgroundColor:'#ececec'}}>
                            <div style={{fontSize:'22px'}}>{data['allData']['pv']}</div>
                        </Card>
                    </Grid.Item>
                </Grid>
                
                
                {globalData.userCategory==0 &&
                    <>
                        <List style={{margin:'20px 0'}}>
                            <List.Item 
                                extra={
                                    <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>人次</div>
                                }
                            >
                                <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>来源用户</div>
                            </List.Item>
                            {data['userData'].map((one,index)=>(
                                <List.Item 
                                    key={index}
                                    extra={<>{one.pv}</>}
                                >
                                    {one.userName}
                                </List.Item>
                            ))}
                        </List>
                    </>
                }

                <List style={{margin:'20px 0'}}>
                    <List.Item 
                        extra={
                            <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>观看人次</div>
                        }
                    >
                        <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>我的妹子</div>
                    </List.Item>
                    {data['girlData'].map((one,index)=>(
                        <List.Item 
                            key={index}
                            extra={<>{one.pv}</>}
                        >
                            {one.girlName}
                        </List.Item>
                    ))}
                </List>
            </div>
        </>
    )
}

